$(document).ready(function () {
    const contactInformations        = $('.js-contactInformations');
    const contactEditForm            = $('.js-contactEditForm');
    const contactEditFormBtn         = $('.js-contactEditFormBtn');

    //customerForm variables initializations
    const customerTypeSelector       = $('.js-customerTypeSelector');
    const customerCountrySelector    = $('.js-customerCountrySelector');

    //toggle form / and contact informations block
    if (contactEditForm.length && contactEditFormBtn.length && contactInformations.length) {
        contactEditFormBtn.on('click', function (e) {
            contactInformations.toggle();
            contactEditForm.toggle();
        });   
    }

    if (customerTypeSelector.length && customerCountrySelector.length) {
        const proTypes = [2, 3, 4];
        var selectedType = parseInt(customerTypeSelector.val());

        if (proTypes.includes(selectedType)) {
            customerCountrySelector.find('selectpicker').prop("disabled", false);
            customerCountrySelector.show();
        }
        else {
            customerCountrySelector.find('selectpicker').prop("disabled", true);
            customerCountrySelector.hide();
        }

        customerTypeSelector.on('change', function(e) {
            var selectedType = parseInt($(this).val());

            //check if customer has pro types
            if (proTypes.includes(selectedType)) {
                customerCountrySelector.find('selectpicker').prop("disabled", false);
                customerCountrySelector.show();
            }
            else {
                //hide country choice
                customerCountrySelector.find('selectpicker').prop("disabled", true);
                customerCountrySelector.hide();
            }
        });
    }
});