$(document).ready(function () {
    //handle favorite btn click
    $(document).on('click', '.js-favoriteTelephoneWidgetBtn', function (e) {
        var newFavoriteTelephoneWidgetBtn = $(this);

        //update icons
        var svgIcon = newFavoriteTelephoneWidgetBtn.find('svg');
        svgIcon.addClass('fa');
        svgIcon.addClass('selected-icon');
        svgIcon.removeClass('far');
        svgIcon.removeClass('unselected-icon');


        //updates previous favorite
        $(".js-favoriteTelephoneWidgetBtn").not(newFavoriteTelephoneWidgetBtn).each(function (index, element) {
            var svgIcon = $(element).find('svg');
            svgIcon.addClass('far');
            svgIcon.addClass('unselected-icon');
            svgIcon.removeClass('fa');
            svgIcon.removeClass('selected-icon');
        });


        //update new favorite checkbox input
        var favoriteInput = newFavoriteTelephoneWidgetBtn.prev().find('input[type=checkbox]');
        favoriteInput.prop('checked', true);
        favoriteInput.val(1);

        //update old favorite checkbox input
        $(".js-isFavoriteTelephoneWidgetValue").not(favoriteInput).each(function (index, element) {
            $(element).prop('checked', false);
            $(element).val(0);
        });
    });

    //Handle click on adding a new telephone btn
    $(document).on('click', '.js-addTelephoneWidgetBtn', function (e) {
        var block = $($(this).attr('data-list-selector'));
        // Try to find the counter of the list or use the length of the list
        var counter = block.data('widget-counter') || block.children().length;

        // grab the prototype template
        var newWidget = block.attr('data-prototype');
        
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your emails
        // end name attribute looks like name="contact[telephones][2]"
        newWidget = newWidget.replace(/__name__/g, counter);
        // Increase the counter
        counter++;

        // And store it, the length cannot be used if deleting widgets is allowed
        block.data('widget-counter', counter);

        // create a new list element and add it to the list
        var newElem = $(block.attr('data-widget-tags')).html(newWidget);
        newElem.appendTo(block);

        //initalize selectpickers
        newElem.find('.selectpicker').each(function() {
            $(this).selectpicker();
        });
    });

    //Handle click on deleting telephone btn
    $(document).on("click", '.js-removeTelephoneWidgetBtn', function(e) {
        let telephoneRow = $(this).closest('.input-group');

        //check if it can be deleted
        let isFavoriteCheckbox = telephoneRow.find('.js-favoriteTelephoneWidgetBtn').closest('.input-group-text').find('input[type=checkbox]');
        if (isFavoriteCheckbox.prop('checked'))
        {
            alert('Vous ne pouvez pas supprimer le numéro de téléphone principale');
            return;
        }
        
        var block = $($(this).attr('data-list-selector'));
        // Try to find the counter of the list or use the length of the list
        var counter = block.data('widget-counter') || block.children().length;
        counter--;
        block.data('widget-counter', counter);

        //pass isActive value to false
        $(this).closest('.input-group').find('.js-isActiveTelephoneWidgetValue').val(0);
        $(this).closest('.input-group').find('.js-telephoneNumberWidgetValue').removeAttr('required');

        //remove or hide the row from DOM
        //if data already exists in db we need to send data to form
        //if not we can delete the dom element
        if (telephoneRow.attr('data-persisted')) {
            telephoneRow.hide();
        }
        else {
            telephoneRow.remove();
        }
    });
});