import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static values = {

        motif: String,
        modePaiement: String,
        orderId: String,
    };

    validPaiementInterne()
    {
        $('.step:not([id=phase_confirmation])').fadeOut(100).addClass('d-none');
        $('#phase_confirmation').removeClass('d-none').fadeIn(100);
        $('html, body').stop().animate({ scrollTop: 0 }, 1000);

        if ($('#chooseOneOrTwoOrder').length == 1) {
            var input = document.createElement('input');
            input.type = 'hidden'; input.name = 'chooseOneOrTwoOrderInput'; input.id = 'chooseOneOrTwoOrderInput';
            document.getElementById('formPanierPaiement').insertAdjacentHTML('beforeend',
                "<input type='hidden' name='chooseOneOrTwoOrderInput' id='chooseOneOrTwoOrderInput' value='" + $('#chooseOneOrTwoOrder').val() + "'>"
            );
        }

        $.ajax({
            type: "POST",
            timeout: 60000,
            url: "/order-finalise",
            data: $('#formPanierPaiement').serialize(),
            dataType: "json"
        })
            .done(function (ret) {

                window.scrollTo({ top: 0, behavior: 'smooth' });
                /* On appel le dataLayer payement */
                if (ret.dataLayer_payement) {
                    $('body').append(ret.dataLayer_payement);
                }
                
                if (ret.success === true) {
                    
                    $('#merci').addClass('d-none');
                    $('#regret').addClass('d-none');
                   
                    // merci
                    $('#merci').removeClass('d-none');

                    if (ret.id_decoupe) {
                        $('#orderIndispoId-channel').text(ret.id_decoupe)
                        $('#merci-channel').addClass('d-none');
                        $('#merci-channel-decoupe').removeClass('d-none');
                    } else {
                        $('#merci-channel').removeClass('d-none');
                        $('#merci-channel-decoupe').addClass('d-none');
                    }

                    $('#regret').addClass('d-none');

                    $('.wait-onload').removeClass('d-flex').addClass('d-none');

                    /* On appel le dataLayer */
                    if (ret.dataLayer) {
                        $('form').after(ret.dataLayer);
                    }
                    // On met visuellement à jour le picto panier dans le header
                    $('.header_basket_count').text(0);
                    $('#header_basket_total').hide();
                    $('#when-0-basket').show();
                    AfterLoad();
                    return true;

                } else {
                    
                    // regret
                    $('#merci').addClass('d-none');
                    $('#regret').removeClass('d-none');
                    $('.wait-onload').removeClass('d-flex').addClass('d-none');
                    AfterLoad();

                    return true;
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                sendPaiementNotificationData('/order-finalise', jqXHR.responseText, $('#formPanierPaiement').serialize());
            });
        
        data = 'champ=' + this.motifValue + '&value=' + this.modePaiementValue + '&order=' + this.orderIdValue;
        $.ajax({ url: '/historisation-commande/client', data: data, method: 'POST', timeout: 5000, async: true })
            .done( /* on ne fait rien */)
            .fail( /* on ne fait rien */);

        return false;
    }
}