import Swiper from 'swiper';
import { Grid, Pagination, Navigation, Autoplay, EffectFade, Thumbs } from 'swiper/modules';
/*===========================
Swiper
===========================*/

document.addEventListener("DOMContentLoaded", function() {

    const swiperDefault = new Swiper('.swiper.default', {
        modules: [Navigation, Grid, Autoplay, EffectFade],

        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },

        grid: {
            rows: 1,
            fill: 'column',
        },

        speed: 1200,
        loop: true,

        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },

    });

    const swiperProduct = new Swiper('.swiper.product', {
        modules: [Navigation, Autoplay],
        watchOverflow: true,

        // Slides grid
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        centeredSlides: true,
        slidesOffsetBefore: 0, // in px
        slidesOffsetAfter: 0, // in px

        // Effect
        effect: 'slide',

        // Navigation
        navigation: {
            nextEl: '.swiper-button-next',
            // prevEl: '.swiper-button-prev',
        },

        // Pagination : https://www.tutorialdocs.com/tutorial/swiper/api-pagination.html
        pagination: {
            el: null,
        },

        // Breakpoints
        breakpoints: {
            640: {
                spaceBetween: 10,
            },
            768: {
                centeredSlides: false,
                spaceBetween: 10,
            },
            1024: {
                centeredSlides: false,
                spaceBetween: 10,
            },
        }

    })

    const swiperBrand = new Swiper('.swiper.brand', {
        modules: [Navigation, Autoplay],
        // Slides grid
        slidesPerView: 2,
        slidesPerGroup: 1,
        centeredSlides: true,
        slidesOffsetBefore: 0, // in px
        slidesOffsetAfter: 0, // in px
        loop: true,

        // Effect
        effect: 'slide',
       
        // Navigation
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // Pagination : https://www.tutorialdocs.com/tutorial/swiper/api-pagination.html
        pagination: {
            el: null,
        },

        // Breakpoints
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 4,
                centeredSlides: false,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 6,
                centeredSlides: false,
                spaceBetween: 50,
            },
        }

    })

    const swiperTrustpilot = new Swiper('.swiper.trustpilot', {
        modules: [Navigation],
        // Slides grid
        slidesPerView: 2,
        slidesPerGroup: 1,
        centeredSlides: false,
        slidesOffsetBefore: 0, // in px
        slidesOffsetAfter: 0, // in px

        // Effect
        effect: 'slide',

        // Navigation
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // Pagination : https://www.tutorialdocs.com/tutorial/swiper/api-pagination.html
        pagination: {
            el: null,
        },

        // Breakpoints
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 50,
            },
        }

    })

    const galleryThumbs = new Swiper('.swiper.gallery-thumbs', {

        modules: [Navigation, Thumbs],
        spaceBetween: 10,
        slidesPerView: 3,
        loop: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });

    const galleryTop = new Swiper('.swiper.gallery-top', {

        modules: [Navigation, Thumbs],
        spaceBetween: 10,
        slidesPerGroup: 1,
        centeredSlides: true,
        slidesOffsetBefore: 0, // in px
        slidesOffsetAfter: 0, // in px
        loop: true, // On définit à false sinon lightGallery va nous embeter ( plusieurs fois la même image )
        noSwipingClass: 'swiper-no-swiping',
        navigation: {
            nextEl: '.swiper-button-next',
            // prevEl: '.swiper-button-prev'
        },
        thumbs: {
            swiper: galleryThumbs
        }
    });

    new Swiper('.swiper.swiper-blog', {

        modules: [Navigation, Pagination, Autoplay, EffectFade],

        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },

        speed: 1200,
        loop: false,

        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },

        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
      
        // // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    });

});