import { Controller } from '@hotwired/stimulus';
//import de notre formVerifInput_controller
import { emailIsValid, toggleError, toggleSuccess } from '../js/formulaireFunction.js';
import { useMutation } from 'stimulus-use';

export default class extends Controller {
    static targets = [

        //Input fields
        'email',
        'password',

        //Divs
        'divPassword',
        'divForgotPassword',
        'divCompteExistant',

        //Input Button
        'continueButton',

        //Form
        'form_mon_compte',

        'titleLogin',
        'titlePassword',

        'errorDisplay'

    ];

    static values = {
        urlInscription: String,
    }

    //Appelé au chargement de la page
    connect() {
        //on reset l'action du formulaire si elle existe
        this.form_mon_compteTarget.action = '';
        //on disable le bouton continuer
        this.continueButtonTarget.disabled = true;

        // document.getElementById('login').addEventListener('input', (event) => {
        //     alert("test");
        // });

        // Ajout de l'écouteur d'événement pour la touche "Entrée"
        this.emailTarget.addEventListener('keypress', (event) => {
            if (event.key === 'Enter') {
                this.continueAction();
            }
        });
        this.passwordTarget.addEventListener('keypress', (event) => {
            if (event.key === 'Enter') {
                this.continueAction();
            }
        });

        var hasValue = $("#login").val().length > 0;//Normal
        if(!hasValue){
            hasValue = $("#login:-webkit-autofill").length > 0;//Chrome
        }

        this.emailTarget.focus();

        useMutation(this, { attributes: true, childList: true, subtree: true });
    }

    triggerCheckEmailValidityOnFocus(event) {
        // Trigger the checkEmailValidity action when the email field receives focus
        this.checkEmailValidity(event);
    }

    checkEmailValidity(event) {
        let errorMessageElt = document.getElementById('invalid-mail-login');
        if (emailIsValid(event.target.value) && event.target.value != '') {
            toggleSuccess(event.target);
            if (errorMessageElt) {
                errorMessageElt.classList.add('d-none');
            }
            //enable button continuer
            this.continueButtonTarget.disabled = false;
        } else {
            toggleError(event.target);
            if (errorMessageElt) {
                errorMessageElt.classList.remove('d-none');
            }
            //disable button continuer
            this.continueButtonTarget.disabled = true;
        }
    }
    //Affiche ou cache le mot de passe
    showPassword() {
        if (this.passwordTarget.type == 'text') {
            this.passwordTarget.type = 'password';
            return;
        } else {
            this.passwordTarget.type = 'text';
            return;
        }
    }

    continueAction() {
        //on appelle la fonction verifEmail de notre formVeirfInput_controller
        if (emailIsValid(this.emailTarget.value) && this.emailTarget.value != '') {
            //on verifie si notre champ mot de passe est visible, si il est visible
            if (this.divPasswordTarget.classList.contains('d-none') == false) {
                this.form_mon_compteTarget.submit();
            } else {
                //On verifie si l'email existe déjà sur la route verifEmail en ajax
                $.ajax({
                    url: `/compte/verifEmail`,
                    type: 'POST',
                    data: {
                        email: this.emailTarget.value
                    },
                    success: (data) => {
                        //Si l'email existe déjà
                        if (data.error == 'undefined') {
                            if (data.emailExists) {
                                //On reset l'action du formulaire
                                this.form_mon_compteTarget.action = '';
                                //On affiche le champ password
                                this.divPasswordTarget.classList.remove('d-none');
                                //On affiche le mot de passe oublié
                                this.divForgotPasswordTarget.classList.remove('d-none');

                            } else {
                                //on re dirige vers la page d'inscription
                                this.form_mon_compteTarget.action = this.urlInscriptionValue;
                                this.form_mon_compteTarget.submit();
                            }
                        } else {
                            const errorElement = document.createElement('div');
                            errorElement.classList.add('error-message');
                            errorElement.innerHTML = data.error;

                            // Remplacez le contenu précédent de l'élément errorDisplay
                            this.errorDisplayTarget.innerHTML = '';
                            this.errorDisplayTarget.appendChild(errorElement);
                        }

                    },
                    error: (error) => {
                        //console.log(error);
                    }
                });

            }
        } else {
            //On affiche le message d'erreur
            toggleError(this.emailTarget);
        }

        //let email = this.emailTarget.value;

    }

    pwdforgotAction(){
        //on cache le bouton continuer
        this.continueButtonTarget.classList.add('d-none');
    }

}