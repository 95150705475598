export function loadModalContact(_type, _slug, _idModal, _customCallback) {
    var __idModal = _idModal || '';
    var _customCallback = _customCallback || function(){};
    $.ajax({
        url: `/load-modal-contact/${_type}/${_slug}`,
        data: {
            idModal: __idModal
        },
        type: 'GET',
        success: function (data) {
            $('body').append(data);
            _customCallback();
        },
        error: function (jqXHR, textStatus, errorThrown) {
            sendNotificationData(`/load-modal-contact/${_type}/${_slug}`, textStatus, errorThrown, 'idModal=' + __idModal);     
        }
    });
}

global.loadModalContact = loadModalContact;