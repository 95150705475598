$(document).ready(function () {

    const loadBasketBtn = $('.js-loadBasketBtn');
    const loadBasketBtnMulti = $('.js-loadBasketBtnMulti');
    const showBasketContentBtn = $('.js-showBasketContentBtn');

    if (loadBasketBtnMulti.length) {
        $(document).on('click', '.js-loadBasketBtnMulti', function (e) {
            let msg = $(this).attr('data-message');
            bootbox.alert(msg);
        });
    }

    if (loadBasketBtn.length) {
        $(document).on('click', '.js-loadBasketBtn', function (e) {
            let url = $(this).attr('data-url');
            let title = $(this).attr('data-title');
            let save = $(this).attr('data-save');
            let cancel = $(this).attr('data-cancel');
            let warningMessage = $(this).attr('data-warningMessage');

            if(typeof warningMessage !== 'undefined' && warningMessage !== false){
                bootbox.confirm({
                    message: $(this).attr('data-warningMessage'),
                    locale: document.documentElement.lang,
                    callback: function (result) {
                        if(result){
                            $.ajax({
                                url: url,
                                type: 'GET',
                                success: function (result) {
                                    displayModal(result, url, title, save, cancel);
                                    initForm(result);
                                }
                            });
                        }
                    }
                });
            }else{
                $.ajax({
                    url: url,
                    type: 'GET',
                    success: function (result) {
                        displayModal(result, url, title, save, cancel);
                        initForm(result);
                    }
                });
            }
        });
    }

    function initForm() {
        $('.selectpicker').each(function () {
            $(this).selectpicker();
        });
    }

    function displayModal(content, url, title, save, cancel) {
        formDialog = bootbox.confirm({
            message: content,
            title: '<i class="fal fa-save fa-3x text-lightgray"></i> <i class="fal fa-angle-right fa-3x text-lightgray"></i><i class="fal fa-shopping-cart fa-3x text-lightgray"></i><br/>' + title,
            centerVertical: true,
            closeButton: false,
            size: 'extra-large',
            buttons: {
                confirm: {
                    label: save
                },
                cancel: {
                    label: cancel
                }
            },
            callback: function (result) {
                if (result) {

                    if ($('#form_basket_loader')[0].checkValidity()) { //check html5 validation
                        $.ajax({
                            type: "POST",
                            url: url,
                            data: $('#form_basket_loader').serialize(),
                            dataType: 'json',
                            success: function (result) {
                                if (result.dataLayer) {
                                    $('body').append(result.dataLayer);
                                }
                                window.location = result.route;
                                bootbox.hideAll();
                            },
                        });
                    } else {
                        $('#form_basket_loader')[0].reportValidity(); //report html5 validation on DOM
                    }

                    return false; //prevent modal to close
                }
            }
        });
    }

    if (showBasketContentBtn.length) {
        $(document).on('click', '.js-showBasketContentBtn', function (e) {
            $(this).parent().find('.basket-content').toggle();
        });
    }
});