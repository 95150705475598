// ne pas utilisé cette fonctionne directement passé par les deux autres
sendNotification = (url, page, textStatus, errorThrown, params, redirection) => {
    var _params = params || '';
    var data = '';
    if (_params != '') {
        data = _params + '&';
    }
    if (page > 1) {
        data = data + 'page=' + page + '&';
    }
    data = data + 'textStatus=' + textStatus + '&error=' + errorThrown;
    // on essaie d'envoyer la notification à slack
    $.ajax({url: url, data: data, method: 'POST', timeout: 5000})
        .done(
            function () {
                traiteRedirectionAfterNotification(redirection);
            }
        )
        .fail( // si ça échoue (WAF, 403) on passe par l'url alt
            function () {
                $.ajax({url: url + '-alt', data: data, method: 'POST', timeout: 5000})
                    // est quoi qu'il arrive on fait une redirection vers la page si besoin
                    .always(function () {
                        traiteRedirectionAfterNotification(redirection);
                    });
            }
        );
};

traiteRedirectionAfterNotification = (redirection) => {
    let _params = redirection || '';
    if (_params != '') {
        window.location.href = _params;
    } else {
        AfterLoad();
    }
};

sendNotificationData = (page, textStatus, errorThrown, params) => {
    sendNotification('/notif-load', page, textStatus, errorThrown, params, '');
};

sendNotificationDataWithRedirection = (page, textStatus, errorThrown, params, redirection) => {
    sendNotification('/notif-load', page, textStatus, errorThrown, params, redirection);
};

sendPaiementNotificationData = (page, sujet, attachements) => {

    let data = {
        page: page,
        sujet: sujet,
        attachements: attachements
    };

    $.ajax({
        url: '/notif-paiement',
        data: data,
        method: 'POST',
        timeout: 5000
    })
};

// sendPaiementNotificationDataWithRedirection = (page, textStatus, errorThrown, params, redirection) => {
//     sendNotification('/notif-paiement', page, textStatus, errorThrown, params, redirection);
// };

WaitForLoad = () => {
    if ($('.modal-backdrop').length == 0) {
        $('body').append('<div class="modal-backdrop fade show" id="waiting-loader">' +
            '<div class="d-flex align-items-center justify-content-center w-100 h-100 rltLoader" style="font-size: 3em; color: white">' +
            $('#rltLoader').html() +
            '</div></div>');
    }
};

AfterLoad = () => {
    $('#waiting-loader').remove();
};