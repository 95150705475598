import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    //Controlleur qui va afficher ou masque les champs de saisie du mot de passe

    //On déclare les targets
    static targets = [
        'inputMdp'
    ];

    static values = {
    }

    //Appelé au chargement de la page
    connect() {

    }

    async togglePassword(event) {
        //on recupere l'input inputMdp
        let input = this.inputMdpTarget;
        //on recupere le type de l'input
        let type = input.getAttribute('type');
        //si le type est password
        if (type === 'password') {
            //changer le type en text
            input.setAttribute('type', 'text');
            //changer l'icone
            event.target.classList.remove('fa-eye');
            event.target.classList.add('fa-eye-slash');
        }
        //sinon
        else {
            //changer le type en password
            input.setAttribute('type', 'password');
            //changer l'icone
            event.target.classList.remove('fa-eye-slash');
            event.target.classList.add('fa-eye');
        }
    }
    
}