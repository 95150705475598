import { Controller } from '@hotwired/stimulus';
import { AdyenCheckout, Dropin } from '@adyen/adyen-web/auto';
import '@adyen/adyen-web/styles/adyen.css';

export default class extends Controller {

    static targets = [];

    static values = {
        environment: String,
        clientKey: String,
        sessionId: String,
        sessionData: String,
        
        idOrder: String,
        reference: String,

        // Adyen avec redirection
        redirectResult: String,
        internalFirst: Boolean,
    };

    connect() { 

        if (this.redirectResultValue) {
            this.gestionRedirectAdyen();
        } else {
            this.initAdyen();
        }
    }

    async initAdyen(event)
    {
        const checkout = await AdyenCheckout(this.configuration(true));
        
        // Create an instance of Drop-in and mount it to the container you created.
        const dropin = new Dropin(checkout, {
            paymentMethodsConfiguration: { 
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                },
                klarna: { useKlarnaWidget: true },
                klarna_account: { useKlarnaWidget: true },
                klarna_paynow: { useKlarnaWidget: true },
            }
          }).mount('#dropin-container');
    }

    async gestionRedirectAdyen() {

        const checkout = await AdyenCheckout(this.configuration());

        checkout.submitDetails({ details: { redirectResult: this.redirectResultValue } });
    }

    configuration(withPaymentMethods = false)
    {
        let configuration = {
            environment: this.environmentValue, // Change to 'live' for the live environment.
            clientKey: this.clientKeyValue, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
            analytics: {
                enabled: true // Set to false to not send analytics data to Adyen.
            },
            beforeSubmit: (data, component, actions) => {

                if (withPaymentMethods) {

                    if (this.orderFinaliseAdyen() == false) {
                        window.location.reload();
                        actions.reject();
                    }
                }

                actions.resolve(data);
            },
            onPaymentCompleted: (result, component) => {
                this.executerPaiementAdyen(result);
            },
            onError: (error, component) => {
                console.error(error.name, error.message, error.stack, component);
            },

        };

        if (withPaymentMethods) {

            configuration.session = {
                id: this.sessionIdValue, // Unique identifier for the payment session.
                sessionData: this.sessionDataValue, // Unique identifier for the payment session.
            };
            
            // Créez une nouvelle instance de MutationObserver pour surveiller les modifications du DOM
            const observer = new MutationObserver((mutationsList, observer) => {

                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                        for (const node of mutation.addedNodes) {
                            if (node.nodeType === 1 && (node.matches('.adyen-checkout__payment-method') || node.querySelector('.adyen-checkout__payment-method'))) {
                                observer.disconnect();
                                this.moveAndHideElementForPayment(this.internalFirstValue);
                                break;
                            }
                        }
                    }
                }
            });

            observer.observe(document.querySelector('#dropin-container'), { childList: true, subtree: true, attributes: true });

        } else {
            configuration.session = {
                id: this.sessionIdValue,
            };
        }   

        return configuration;
    }

    orderFinaliseAdyen()
    {
        let final_retour = false;

        $.ajax({
            type: "POST",
            async : false,
            url: "/paiement/order-finalise-adyen",
            data: {
                'idOrder': this.idOrderValue,
                'reference': this.referenceValue,
            },
            dataType: "json"

        }).done(function (result) {

            if (result.success == true) {
                final_retour = true;
            } else {
                final_retour = false;
            }

        });

        return final_retour;
    }

    executerPaiementAdyen(result)
    {
        $('.step:not([id=phase_confirmation])').fadeOut(100).addClass('d-none');
        $('#phase_confirmation').removeClass('d-none').fadeIn(100);
        $('html, body').stop().animate({ scrollTop: 0 }, 1000);
      
        $.ajax({
            type: "POST",
            timeout: 60000,
            url: "/paiement/executer-paiement-adyen",
            data: {
                'resultCode': result.resultCode,
                'idOrder': this.idOrderValue,
                'reference': this.referenceValue,
            },
            dataType: "json"
        })
            .done(function (ret) {

                window.scrollTo({ top: 0, behavior: 'smooth' });

                /* On appel le dataLayer payment */
                if (ret.dataLayer_payment) {
                    $('body').append(ret.dataLayer_payment);
                }

                if (ret.success === true) {

                    // si on a une url on redirige
                    if (typeof ret.url !== 'undefined') {
                        window.location.href = ret.url;
                    }

                } else {
                    sendPaiementNotificationData('/paiement/executer-paiement-adyen', ret.success, JSON.stringify(ret));
                    window.location.href = '/';
                }
                
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                sendPaiementNotificationData('/paiement/executer-paiement-adyen', errorThrown, JSON.stringify(jqXHR.responseText));
            });
      
        return true;
    }

    moveAndHideElementForPayment(internalFirst = true) {
        // Sélectionner toutes les divs et cacher la seconde
        const allDivs = document.querySelectorAll('.adyen-checkout__dropin.adyen-checkout__dropin--ready');
        const firstDiv = allDivs[0]; // Sélectionne la deuxième div
        const secondDiv = allDivs[1]; // Sélectionne la deuxième div

        // Sélectionner les deux listes UL
        const allLists = document.querySelectorAll('.adyen-checkout__payment-methods-list');
        const firstList = allLists[0]; // Première liste UL
        const secondList = allLists[1]; // Deuxième liste UL

        // Déplacer les éléments LI d'une liste à l'autre selon le paramètre
        if(internalFirst){
            firstDiv.style.display = 'none'; // Cache la deuxième div
            Array.from(firstList.children).forEach(li => secondList.appendChild(li));
        } else {
            secondDiv.style.display = 'none'; // Cache la deuxième div
            Array.from(secondList.children).forEach(li => firstList.appendChild(li));
        }
    }

    changePaiementAdyen(event)
    {
        let idPaiement = 47;
        let li = event.target.closest('div.adyen-checkout__payment-method');

        if (li && this.element.contains(li)) {
            if(li.id){
                let _id = li.id.split('recap');
                idPaiement = _id[1];
            }
            
            const elements = document.querySelectorAll('.adyen-checkout__payment-method--selected');

            if (document.getElementById('id_modePaiement') && li.id) {
                document.getElementById('id_modePaiement').value = idPaiement;
            }

            // Itérez sur chaque élément et supprimez les classes
            elements.forEach(element => {
                element.classList.remove('adyen-checkout__payment-method--selected');
                element.classList.remove('adyen-checkout__payment-method--next-selected');
                element.classList.remove('.adyen-checkout__payment-method__name--selected');
                element.querySelector('.adyen-checkout__payment-method__header__title').setAttribute('aria-checked', 'false');
                element.querySelector('.adyen-checkout__payment-method__header__title').setAttribute('aria-expanded', 'false');
                element.querySelector('.adyen-checkout__payment-method__name_wrapper').setAttribute('aria-hidden', 'true');
                element.querySelector('.adyen-checkout-pm-details-wrapper').setAttribute('aria-hidden', 'true');

            });
            
            let previousElement = li.previousElementSibling;
            if (previousElement) {
                previousElement.classList.add('adyen-checkout__payment-method--next-selected');
            }

            li.querySelector('.adyen-checkout__payment-method__header__title').setAttribute('aria-checked', 'true');
            li.querySelector('.adyen-checkout__payment-method__header__title').setAttribute('aria-expanded', 'true');
            li.querySelector('.adyen-checkout__payment-method__name_wrapper').setAttribute('aria-hidden', 'false');
            li.querySelector('.adyen-checkout-pm-details-wrapper').setAttribute('aria-hidden', 'false');

            li.classList.add('adyen-checkout__payment-method--selected');
            li.querySelector('span.adyen-checkout__payment-method__name').classList.add('adyen-checkout__payment-method__name--selected');
            this.addHistoriqueForOrder('modePaiement', idPaiement, $('#id_order').val());
        }
    };

    addHistoriqueForOrder(champ, value, order)
    {
        let data = 'champ=' + champ + '&value=' + value + '&order=' + order;
        $.ajax({ url: '/historisation-commande/client', data: data, method: 'POST', timeout: 5000, async: true })
            .done( /* on ne fait rien */)
            .fail( /* on ne fait rien */);
    }

}