$(document).ready(function () {

    const addContactBtn = $('.js-addContactBtn');
    const editContactBtn = $('.js-editContactBtn');
    const showContactBtn = $('.js-showContactBtn');
    const removeContactBtn = $('.js-removeContactBtn');

    let showModal = false;

    //Create contact form
    if (addContactBtn.length) {
        $(document).on('click', '.js-addContactBtn', function (e) {
            let url = $(this).attr('data-url');            
            let modalTitle = $(this).attr('data-modalTitle');

            if (showModal == false) {
                showModal = true;
                //get content for form
                $.ajax({
                    url: url,
                    type: 'GET',
                    success: function (result) {                        
                        displayModal(result, modalTitle, url);
                        initForm(result);
                    }
                });
            }
        });
    }

    if (editContactBtn.length) {
        $(document).on('click', '.js-editContactBtn', function (e) {
            let url = $(this).attr('data-url');
            let modalTitle = $(this).attr('data-modalTitle');

            if (showModal == false) {
                showModal = true;
                //get content for form
                $.ajax({
                    url: url,
                    type: 'GET',
                    success: function (result) {
                        displayModal(result, modalTitle, url);
                        initForm(result);
                    }
                });
            }
        });
    }

    if (removeContactBtn.length) {
        $(document).on('click', '.js-removeContactBtn', function (e) {
            let url = $(this).attr('data-url');
            let modalTitle = $(this).attr('data-modalTitle');
            let btnValidTrad = document.getElementById('BtnValidTrad').getAttribute('data-value');
            let btnCloseTrad = document.getElementById('BtnCloseTrad').getAttribute('data-value');

            //get content for form
            $.ajax({
                url: url,
                type: 'GET',
                success: function (result) {
                    formDialog = bootbox.confirm({
                        message: result,
                        title: modalTitle,
                        centerVertical: true,
                        closeButton: false,
                        buttons: {
                            confirm: {
                                label: btnValidTrad
                            },
                            cancel: {
                                label: btnCloseTrad
                            }
                        },
                        callback: function (result) {
                            var idContact = $('.form_contact').attr('data-id') || null;
                            if (result) {
                                $.ajax({
                                    type: "DELETE",
                                    url: url, 
                                    dataType: 'json',
                                    success: function (result) {
                                        //remove delete contact
                                        $('.card-contact[data-id="' + result + '"]').remove();

                                        bootbox.hideAll();
                                        showModal = false;
                                    }
                                });
                                return false; //prevent modal to close
                            } else {
                                showModal = false;
                            }
                        }
                    });
                }
            });
        });
    }

    if (showContactBtn.length) {
        $(document).on('click', '.js-showContactBtn', function (e) {
            let url = $(this).attr('data-url');
            let modalTitle = $(this).attr('data-modalTitle');

            if (showModal == false) {
                showModal = true;
                $.ajax({
                    url: url,
                    type: 'GET',
                    success: function (result) {
                        bootbox.dialog({
                            message: result,
                            title: modalTitle,
                            size: 'large',
                            centerVertical: true,
                            closeButton: true,
                        });
                        showModal = false;
                    }
                })
            }
        });
    }

    function initForm() {
        $('.selectpicker').each(function () {
            $(this).selectpicker();
        });
    }

    function displayModal(content, modalTitle, url) {
        let btnValidTrad = document.getElementById('BtnValidTrad').getAttribute('data-value');
        let btnCloseTrad = document.getElementById('BtnCloseTrad').getAttribute('data-value');

        formDialog = bootbox.confirm({
            message: content,
            title: modalTitle,
            centerVertical: true,
            closeButton: false,
            buttons: {
                confirm: {
                    label: btnValidTrad
                },
                cancel: {
                    label: btnCloseTrad
                }
            },
            callback: function (result) {
                var idContact = $('.form_contact').attr('data-id') || null;
                if (result) {
                    if ($('.form_contact')[0].checkValidity()) { //check html5 validation
                        $.ajax({
                            type: "POST",
                            url: url,
                            data: $('.form_contact').serialize(),
                            dataType: 'json',
                            success: function (result) {
                                if (idContact !== null) {
                                    //remove edited contact
                                    $('.card-contact[data-id="' + idContact + '"]').remove();
                                }
                                $('.js-contacts-list').prepend(result);
                                bootbox.hideAll();
                                showModal = false;
                            },
                            error: function (result) { //catch server result in case of form errors
                                //replace form content
                                var formBlock = $('.form_contact').parent();
                                $('.form_contact').remove();
                                formBlock.html(result.responseText);
                                initForm();
                            }
                        });
                    } else {
                        $('.form_contact')[0].reportValidity(); //report html5 validation on DOM
                    }

                    return false; //prevent modal to close
                } else {
                    showModal = false;
                }
            }
        });
    }
});