import '../modules/findClasses.js';

export default function clickGtagAndLocation(_category, _label, _value, _location) {
    var _redirect = _location || '';
    // MIT2023-345 - CRD - 2023-04-18 - Gestion de didomi sur KNL 
    // MIT2023-214 - CRD - 2023-06-04 - Gestion de didomi sur KNL(déjà fait)KBE, RBE, BEU, KAT, RES
    // MIT2023-578 - CRD - 2023-06-06 - Gestion didomi sur CIT, KDE, BCOM, RCOM, BCOUK
    // MIT2023-239 - CLE - 2023-06-28 - Suppression CookieBot
    if (typeof analytics != 'undefined') {
        analytics.track('click', {
            category: _category,
            label: _label,
            value: _value
        });
    }
    // ga('send', 'event', _category, 'click', _label, _value); //
    if (_redirect != '') {
        window.location = _redirect;
    }
    return false;
}

global.clickGtagAndLocation = clickGtagAndLocation;


