import { Controller } from '@hotwired/stimulus';
import { toggleError, toggleSuccess, toggleEmpty, slideToggle, emailIsValid } from '../js/formulaireFunction.js';


export default class extends Controller {
    //Controlleur qui va afficher ou masque les champs de saisie du mot de passe

    //On déclare les targets
    static targets = [

        //Input text
        'inputTypeClientParticulier',
        'inputTypeClientProfessionnel',
        'inputNom',
        'inputPrenom',
        'inputMdp',
        'inputMdp2',
        'inputEmail',
        'inputTelephone',
        'inputNewsletter',
        'inputTypeTelephone',
        'inputCountryTelephone',

        'inputRaisonSociale',
        'inputDivNumEori',
        'inputNumEori',
        'inputListePays',
        'inputPays',
        'inputTvaIntra',
        'inputTvaIntra1',
        'inputTvaIntra2',
        'inputBackto',
        'inputSiret',
        'inputCodePostal',
        'inputVille',
        'inputAdresse',
        'inputComplement',
        'inputTypeFormPro',

        // Gestion Insee
        'formulaireInsee',
        'formulaireAncien',
        'formCommun',

        //Btn
        'btnSubmit',
    ];

    static values = {

    }

    //Appelé au chargement de la page
    connect() {
        $('.spinner').addClass('hidden');
    }

    async verifIsNotEmpty(target) {
        //on verifie que notre Input n'est pas vide
        if (target.value.trim() === "" || target.value.trim() === null) {
            toggleEmpty(target);
            return false;
        } else {
            return true;
        }
    }

    async verifInput(target) {
        if (await this.verifIsNotEmpty(target)) {
            toggleSuccess(target);
            return true;
        } else {
            return false;
        }
    }

    async verifEmail(target) {
        if (await this.verifIsNotEmpty(target)) {
            //on verifie que notre Email est correctement formé
            var re = emailIsValid(target.value);
            if (re) {
                toggleSuccess(target);
                return true;
            } else {
                toggleError(target);
                return false;
            }
        } else {
            return false;
        }
    }

    async verifPhone(target) {

        // Si une promesse en cours existe, annulez-la
        if (this.currentPhonePromise) {
            this.currentPhonePromise.abort();
        }

        if (await this.verifIsNotEmpty(target)) {
            // Stockez la promesse dans la variable currentPhonePromise
            this.currentPhonePromise = $.ajax({
                data: {
                    phoneNumber: target.value,
                },
                url: '/validate-phone-number',
                type: 'POST',
                success: (valid, resolve) => {
                    if (valid) {
                        target.setCustomValidity("");
                        toggleSuccess(target);
                    } else {
                        target.setCustomValidity("Invalid phone number");
                        toggleError(target);
                    }
                }
            });

            return this.currentPhonePromise.then((valid) => valid).catch(() => false);
        } else {
            return false;
        }
    }


    async verifPassword(target) {
        if (await this.verifIsNotEmpty(target)) {
            const password = target.value;

            if (password.length < 8) {
                toggleError(target);
                return false;
            } else {
                // Si le champ est le deuxième mot de passe, vérifiez la correspondance
                if (target.id === 'mdpClient2') {
                    const firstPassword = this.inputMdpTarget.value;
                    if (password === firstPassword) {
                        // Les mots de passe correspondent
                        toggleSuccess(target);
                        return true;
                    } else {
                        // Les mots de passe ne correspondent pas
                        toggleError(target);
                        return false;
                    }
                }
                toggleSuccess(target);
                return true;
            }
        } else {
            return false;
        }
    }

    //On vérifie que tous les champs sont correctement remplis
    async verifForm() {
        //Champs formulaire particulier && professionnel
        const verifNom = await this.verifInput(this.inputNomTarget);
        const verifPrenom = await this.verifInput(this.inputPrenomTarget);
        const verifEmail = await this.verifEmail(this.inputEmailTarget);
        const verifPassword = await this.verifPassword(this.inputMdpTarget);
        const verifPassword2 = await this.verifPassword(this.inputMdp2Target);
        const verifPhone = await this.verifPhone(this.inputTelephoneTarget);




        if (await this.typeClient() == 1) {
            if (verifNom && verifPrenom && verifEmail && verifPassword && verifPassword2 && verifPhone) {
                //on active le button créer un compte
                this.btnSubmitTarget.disabled = false;
                return true;
            } else {
                //on desactive le button créer un compte
                this.btnSubmitTarget.disabled = true;
                return false;
            }
        } else {

            if (verifNom && verifPrenom && verifEmail && verifPassword && verifPassword2 && verifPhone) {
                //on active le button créer un compte
                this.btnSubmitTarget.disabled = false;
                return true;
            } else {
                //on desactive le button créer un compte
                this.btnSubmitTarget.disabled = true;
                return false;
            }
        }

    }

    async typeClient() {
        if (this.inputTypeClientProfessionnelTarget.checked) {
            return this.inputTypeClientProfessionnelTarget.value;
        } else {
            return this.inputTypeClientParticulierTarget.value;
        }
    }

    async createAccount(event) {
        //On verifie les champs du formulaire
        const verifForm = await this.verifForm();

        if (verifForm) {
            //on affiche le spinner
            $('.spinner').removeClass('hidden');
            //on desactive le button créer un compte
            this.btnSubmitTarget.disabled = true;
            //on recupere la newsletter si elle est checked on envoie 1 sinon 0
            const newsletter = this.inputNewsletterTarget.checked ? 1 : 0;


            //on créer une requete ajax
            $.ajax({
                type: "POST",
                url: `/compte/addAccount`,
                dataType: 'json',
                data: {
                    action: 'inscription',
                    newsletter: newsletter,
                    email: this.hasInputEmailTarget ? this.inputEmailTarget.value : undefined,
                    id_typeTelephone_0: this.hasInputTypeTelephoneTarget ? this.inputTypeTelephoneTarget.value : undefined,
                    id_country_tel__0: this.hasInputCountryTelephoneTarget ? this.inputCountryTelephoneTarget.value : undefined,
                    telephone_0: this.hasInputTelephoneTarget ? this.inputTelephoneTarget.value : undefined,
                    id_typeClient: await this.typeClient(),
                    num_eori: this.hasInputNumEoriTarget ? this.inputNumEoriTarget.value : undefined,
                    pays: this.hasInputPaysTarget ? this.inputPaysTarget.value : undefined,
                    TVA_intra_1: this.hasInputTvaIntra1Target ? this.inputTvaIntra1Target.value : undefined,
                    TVA_intra_2: this.hasInputTvaIntra2Target ? this.inputTvaIntra2Target.value : undefined,
                    mdpClient: this.hasInputMdpTarget ? this.inputMdpTarget.value : undefined,
                    mdpClient2: this.hasInputMdp2Target ? this.inputMdp2Target.value : undefined,
                    nom: this.hasInputNomTarget ? this.inputNomTarget.value : undefined,
                    prenom: this.hasInputPrenomTarget ? this.inputPrenomTarget.value : undefined,
                    raisonSociale: this.hasInputRaisonSocialeTarget ? this.inputRaisonSocialeTarget.value : undefined,
                    backto: this.hasInputBacktoTarget ? this.inputBacktoTarget.value : undefined,
                    siret: this.hasInputSiretTarget ? this.inputSiretTarget.value : undefined,
                    codePostal: this.hasInputCodePostalTarget ? this.inputCodePostalTarget.value : undefined,
                    ville: this.hasInputVilleTarget ? this.inputVilleTarget.value : undefined,
                    adresse: this.hasInputAdresseTarget ? this.inputAdresseTarget.value : undefined,
                    complement: this.hasInputComplementTarget ? this.inputComplementTarget.value : undefined,
                    eori: this.hasInputNumEoriTarget ? this.inputNumEoriTarget.value : undefined,
                    typeFormPro: this.hasInputTypeFormProTarget ? this.inputTypeFormProTarget.value : undefined,
                },
                success: function (__ret) {
                    $('body').append(__ret.dataLayer);
                    if (__ret.erreur.length > 0) {
                        for (var __i = 0; __i < __ret.erreur.length; __i++) {
                            vf.toggleError($('#' + __ret.erreur[__i]));
                        }
                    }

                    if (__ret.message !== '') {
                        $('#div_alert').html('');
                        $('#div_alert').append(__ret.message);
                        window.top.window.scrollTo(0, 0);
                    }

                    $('.spinner').addClass('hidden');

                    if (__ret.redirect !== '' && __ret.redirect !== undefined) {
                        window.location.href = __ret.redirect;
                    }
                    return false;
                },
                error: function (__ret) {
                    if (__ret.message !== '') {
                        $('#div_alert').html('');
                        $('#div_alert').append(__ret.message);
                        window.top.window.scrollTo(0, 0);
                    }
                    $('.spinner').addClass('hidden');
                    return false;
                }
            });


        }
    }

    /* CHARGEMENT DES FORMULAIRES */
    //Chargement du formulaire particulier et Pro
    async loadFormStandard() {
        await this.loadForm();
    }

    //Chargement de l'ancien formulaire (sans data) si aucune information n'a était trouvé par l'insee pour les pro
    async loadFormOld(e) {
        this.selectPays(e, true);
        $('#div_alert').remove();
    }

    //Chargement des différents formulaires
    async loadForm() {
        $('.spinner').removeClass('hidden');
        $.ajax({
            url: `/compte/loadFormPro`,
            data: {
                menuType: await this.typeClient(),
                email: this.hasInputEmailTarget ? this.inputEmailTarget.value : undefined,
            },
            type: 'POST',
            success: function (result) {
                $('#mainForm').html(result.content);
                $('.selectpicker').selectpicker();
                $('#div_alert').empty();
                $('.spinner').addClass('hidden');
            }
        });
    }

    async verifInfoInsee(e) {
        let that = this;
        $('.spinner').removeClass('hidden');
        $.ajax({
            url: `/compte/verifInformationsInsee`,
            data: {
                raisonSociale: $('#raisonSociale').val().trim(),
                codePostal: $('#codePostal').val().trim(),
                siret: $('#numInsee').val().replace(/\s/g, ''),
                email: this.hasInputEmailTarget ? this.inputEmailTarget.value : undefined,
            },
            type: 'POST',
            success: function (result) {
                if (result.erreur) {
                    $('#div_alert').empty();
                    $('#div_alert').append(result.erreur);
                    window.top.window.scrollTo(0, 0);
                    //Bouton Formulaire classique
                    $("#goToBasicProForm").on('click', function () {
                        that.selectPays(e, true);
                        $('#div_alert').append('');
                    });
                } else {
                    $('#mainForm').html(result.content);
                    $('#div_alert').empty();
                    $('.selectpicker').selectpicker();
                    $('#div_alert').empty();
                }
                $('.spinner').addClass('hidden');
            }
        });
    }

    async selectPays(event, forceForm = false) {
        if (this.inputTypeClientProfessionnelTarget.checked) {

            // Si le pays est la France, alors on affiche le formulaire de l'insee
            if (this.inputPaysTarget.value == '65' && forceForm == false) {
                this.formulaireInseeTarget.style.display = 'block';
                this.formulaireAncienTarget.style.display = 'none';
                this.formCommunTarget.style.display = 'none';
            } else {
                this.formulaireInseeTarget.style.display = 'none';
                this.formulaireAncienTarget.style.display = 'block';
                this.formCommunTarget.style.display = 'block';
            }

            const selectedOption = this.inputPaysTarget.options[this.inputPaysTarget.selectedIndex];
            const dataZoneValue = selectedOption.getAttribute('data-zone');

            const isoValue = selectedOption.getAttribute('data-iso');
            const tvaIntraSelect = document.getElementById('TVA_intra_1');
            const tvaOptionToSelect = tvaIntraSelect.querySelector(`option[value="${isoValue}"]`);

            if (tvaOptionToSelect) {
                tvaOptionToSelect.selected = true;

                const tvaDropdownToggle = document.querySelector('.dropdown-toggle[data-id="TVA_intra_1"]');
                tvaDropdownToggle.setAttribute('title', isoValue);

                const tvaFilterOption = tvaDropdownToggle.querySelector('.filter-option');
                tvaFilterOption.textContent = isoValue;
            }

            if (this.inputPaysTarget.value == '65') {
                await this.showSiret();
            } else {
                await this.hideSiret();
            }

            if (this.inputPaysTarget.value == '65' || dataZoneValue != 'Europe' || this.inputPaysTarget.value == 132) {
                await this.hideTva();
            } else {
                await this.showTva();
            }

            /* On affiche l'eori uniquement pour le United Kingdom */
            if (this.inputPaysTarget.value == 132) {
                await this.showEori();
            } else {
                await this.hideEori();
            }
        }
    }



    /* Différentes fonction permettant d'afficher des champs dans le formulaire PRO */
    
    async showSiret() {
        $('#Label_siret').show();
        document.getElementById('siret').required = true;
        document.getElementById('siret').disabled = false;
    }

    async hideSiret() {
        $('#Label_siret').hide();
        document.getElementById('siret').required = false;
        document.getElementById('siret').disabled = true;
    }
    
    async showTva() {
        const isUpTva = this.isTvaView();
        if (!isUpTva) {
            slideToggle(this.inputTvaIntraTarget, 500);
            this.isUpTva = true;
            this.inputListePaysTarget.style.paddingBottom = '30px';
        }
    }

    async hideTva() {
        const isUpTva = this.isTvaView();
        if (isUpTva) {
            slideToggle(this.inputTvaIntraTarget, 500);
            this.inputListePaysTarget.style.paddingBottom = '40px';
        }
    }

    isTvaView() {
        //return true si l'element est affiche sinon return false
        if (this.inputTvaIntraTarget.style.display == 'none') {
            return false;
        } else {
            return true;
        }
    }

    async showEori() {
        const isUpEori = this.isEoriView();
        if (!isUpEori) {
            slideToggle(this.inputDivNumEoriTarget, 500);
            this.isUpEori = true;
        }
    }

    async hideEori() {
        const isUpEori = this.isEoriView();
        if (isUpEori) {
            slideToggle(this.inputDivNumEoriTarget, 500);
            this.isUpEori = false;
        }
    }


    isEoriView() {
        //return true si l'element est affiche sinon return false
        if (this.inputDivNumEoriTarget.style.display == 'none') {
            return false;
        } else {
            return true;
        }
    }


}