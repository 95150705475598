import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = [
        'replace',
        'append',
        'prepend',
        'inner'
    ];
    targetsValueFiltered = [];
    targetsValueFilteredNeedConnect = [];

    _targetPromise(targetsValue, type) {

        // on récupère les routes en replace avec dedoublonnage des appels
        this.targetsValueFiltered = targetsValue.reduce(
            (carry, element) => {
                let route = element.getAttribute('async-loader');
                let needConnect = element.getAttribute('async-loader-need-connected') || 'false';
                let groupage = element.getAttribute('async-loader-group') || route;
                if (needConnect == 'false') {
                    if (carry[groupage] == undefined) {
                        carry[groupage] = {
                            type: type,
                            isGroup: (groupage == route) ? false : true,
                            elements: []                        
                        };
                    }
                    carry[groupage].elements.push(element);
                }
                return carry;
            }, this.targetsValueFiltered
        );
    
        this.targetsValueFilteredNeedConnect = targetsValue.reduce(
            (carry, element) => {
                let route = element.getAttribute('async-loader');
                let needConnect = element.getAttribute('async-loader-need-connected') || 'false';
                let groupage = element.getAttribute('async-loader-group') || route;
                if (needConnect == 'true') {
                    if (carry[groupage] == undefined) {
                        carry[groupage] = {
                            type: type,
                            isGroup: (groupage == route) ? false : true,
                            elements: []                        
                        };
                    }
                    carry[groupage].elements.push(element);
                }
                return carry;
            }, this.targetsValueFilteredNeedConnect 
        );
        // console.log(this.targetsValueFiltered);
        // console.log(this.targetsValueFilteredNeedConnect);
    }

    _isConnected() {
        const userInfo = document.getElementById('user-info');
        if(userInfo == null) return false;  
        return (userInfo.getAttribute('connected') || 'unconnected') == 'connected'? true : false;
    }

    _initTooltip( element ) {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
    }    

    //Appelé au chargement de la page
    async connect() {
        let requests = [];
        let requestsNeedConnect = [];

        if (this.hasReplaceTarget) {
            this._targetPromise(this.replaceTargets, 'replace');
        }

        if (this.hasAppendTarget) {
            this._targetPromise(this.appendTargets, 'append');
        }

        if (this.hasPrependTarget) {
            this._targetPromise(this.prependTargets, 'prepend');
        }

        if (this.hasInnerTarget) {
            this._targetPromise(this.innerTargets, 'inner');
        }

        Object.entries(this.targetsValueFiltered).map(entry => {
            const [route, obj] = entry;
            requests.push(new Promise((resolve) => {
                this.getAsyncLoader(route, obj.elements, obj.type, obj.isGroup, resolve);
            }));
        });
                   
        await Promise.all(requests).then(() => {
            if (this._isConnected()) {
                Object.entries(this.targetsValueFilteredNeedConnect).map(entry => {
                    // console.log(entry);
                    const [route, obj] = entry;
                    requests.push(new Promise((resolve) => {
                        this.getAsyncLoader(route, obj.elements, obj.type, obj.isGroup, resolve);
                    }));
                });        
                Promise.all(requestsNeedConnect).then(() => {
                    // fin du traitement
                });
            }
        });

    }

    applyUpdateToElement(element, content, type)
    {
        if (content != null) {
            switch (type) {
                case 'append':
                    element.innerHTML = element.innerHTML + content;
                    break;
                case 'prepend':
                    element.innerHTML = content + element.innerHTML;
                    break;
                case 'inner':
                    element.innerHTML = content;
                    break;
                case 'replace':
                default:
                    element.outerHTML = content;
                    break;
            }
            this._initTooltip();
        } else {
            element.remove();
        }
    }

    getAsyncLoaderGroupage(route, elements, type, resolve) {
        let _route = route;
        let _dataFirst = [];
        let _dataSecond = [];
        let _data = [];
        _dataFirst = elements.reduce(
            (carry, element) => {
                let route = element.getAttribute('async-loader');
                carry.push({ route: route, id: element.getAttribute('id') });
                return carry;
            }, _dataFirst
        );
        _dataSecond = _dataFirst.reduce((carry, element) => {
            if (carry[element.route+'-'+element.id] == undefined) {
                carry[element.route+'-'+element.id] = { route: element.route, id: element.id };
            }
            return carry;
        }, _dataSecond);
        _data = Object.values(_dataSecond);
        let formData = new FormData();
        formData.append('data', JSON.stringify(_data));
        fetch(_route, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json'
            },
            method: 'POST',
            body: formData
        })
            .then((response) => response.json())
            .then(result => {
                Array.from(result).forEach((element) => {
                    let _elements = document.querySelectorAll('#'+element.id);
                    let content = element.content;
                    _elements.forEach((_element) => {
                        this.applyUpdateToElement(_element, content, type)
                    });
                });
                resolve();
            });

    }

    getAsyncLoaderSingle(route, elements, type, resolve)
    {
        fetch(route, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
            .then((response) => response.text())
            .then(result => {
                elements.forEach((element) => {
                    this.applyUpdateToElement(element, result, type)
                });
                resolve();
            });
    }

    async getAsyncLoader(route, elements, type, isGroup, resolve)
    {
        if (isGroup) {
            this.getAsyncLoaderGroupage(route, elements, type, resolve);           
        } else {
            this.getAsyncLoaderSingle(route, elements, type, resolve);                  
        }
    }

}