$(document).ready(function () {
    const customerProInformations = $('.js-customerProInformations');
    const customerProEditForm = $('.js-customerProEditForm');
    const customerProEditFormBtn = $('.js-customerProEditFormBtn');

    //toggle form / and pro customer informations block
    if (customerProEditForm.length && customerProEditFormBtn.length && customerProInformations.length) {
        customerProEditFormBtn.on('click', function (e) {
            customerProInformations.toggle();
            customerProEditForm.toggle();
        });
    }
});