function buttonOnLoad(_id) {
    $(`${_id} > span`).addClass('d-none');
    $(`${_id} > .wait-onload`).removeClass('d-none').removeClass('h-35px').addClass('d-flex').addClass('h-24px');
    $(_id).attr('disabled', 'disabled');
}

function labelOnLoad(_id) {
    $(`${_id} > .wait-onload`).toggleClass('d-none').toggleClass('d-flex');
}
global.buttonOnLoad = buttonOnLoad;
global.labelOnLoad = labelOnLoad;
