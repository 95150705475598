import { Controller } from '@hotwired/stimulus';
import { fetchCall } from '../js/helper/fetch.js';

export default class extends Controller {

    static targets = [
        "formBonDeCommande",
        "errorMessage",
    ];

    static values = {};

    connect() {}

    async bonDeCommande(event) {

        event.preventDefault();

        let data = new FormData(this.formBonDeCommandeTarget);

        let bonDeCommande = await fetchCall(this.formBonDeCommandeTarget.getAttribute('action'), "POST", data, false);

        if (bonDeCommande.status == "success") {

            document.getElementById('container_bonDeCommande').outerHTML = bonDeCommande.view;
            document.getElementById('close-modal').click();

        } else {

            this.errorMessageTarget.classList.remove('d-none');
            this.errorMessageTarget.innerHTML = bonDeCommande.message;
        }
    }
}