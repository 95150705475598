import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = [
        'countHour',
        'countMinute',
        'countSecond'
    ];

    static values = {
        timeDeliveryHour: String,
        timeDeliveryMinute: String,
        timeDeliverySecond: String
    }

    connect() {
        if (this._updateCountdown()) {
            setInterval(() => this._updateCountdown(), 1000);
        }
    }

    _updateCountdown() {
        // [MIT2024-1145] Erreur de Countdown départ chez certains Clients - QGT
        let date = (new Date(new Date().toLocaleString('en', {timeZone: 'Europe/Paris'})));
        // let date = (new Date()); // Horaire du navigateur du client
        let d =
            this.dateChrono(
                this.timeDeliveryHourValue - date.getHours(),
                this.timeDeliveryMinuteValue - date.getMinutes(),
                this.timeDeliverySecondValue - date.getSeconds(),
                date.getMonth(),
                date.getDate(),
                date.getFullYear(),
            );
        if (!isNaN(d.getTime())) {
            this.countHourTarget.innerHTML = "<span class='position'>" + d.getHours() + "</span><span class='word'>h </span>";
            this.countMinuteTarget.innerHTML = "<span class='position'>" + d.getMinutes() + "</span><span class='word'>min </span>";
            this.countSecondTarget.innerHTML = "<span class='position'>" + d.getSeconds() + "</span><span class='word'>s </span>";
            return true;
        } else {
            this.countHourTarget.innerHTML = "<span class='position'>??</span><span class='word'>h </span>";
            this.countMinuteTarget.innerHTML = "<span class='position'>??</span><span class='word'>min </span>";
            this.countSecondTarget.innerHTML = "<span class='position'>??</span><span class='word'>s </span>";
            return false;
        }

    }

    dateChrono(hour, minute, second, month, day, year) {
        return (new Date(year, month, day, hour, minute, second));
    }
    


}