export function toggleSeoText(element) {

    let btnLirePlus = document.querySelector('[data-read-more]');
    let btnLireMoins = document.querySelector('[data-read-less]');

    if (btnLirePlus) {
        
        btnLirePlus.addEventListener('click', function (e) {
            btnLirePlus.classList.add('d-none');
            btnLireMoins.classList.remove('d-none');
            $(element).fadeToggle();
        });
    
        btnLireMoins.addEventListener('click', function (e) {
            btnLirePlus.classList.remove('d-none');
            btnLireMoins.classList.add('d-none');
            $(element).fadeToggle();
        });
    }

}

global.toggleSeoText = toggleSeoText;