import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {

    static debounces = [
        'keyup', { name: 'headerResearch' }
    ];

    static targets = [
        'searchHeaderSubmit',
        'suggestionList',
        'searchCount',
        'searchText',
        'suggestionListProducts',
        'dropdownSearch',
        'searchHeaderInput',
        'bgDropDownSearch'
    ];

    controller = null;
 
    connect() {
        useDebounce(this, { wait: 300 });

        const dropdownSearch = document.getElementById('bg-dropdown-search');
        dropdownSearch.addEventListener('click', function (e) {
            dropdownSearch.style.display = 'none';
            document.getElementById('dropdown-search').style.display = 'none';
            document.body.style.overflow = 'auto';
        })
    }

    submitSearch(event) {
        this.searchHeaderSubmitTarget.click();
    }


    headerResearch(event) {
        const nbLimit = 3;
        const inputSearch = event.target;
        
        if (inputSearch.value.length < nbLimit) {
            event.preventDefault();
            return false;
        }
        this.bgDropDownSearchTarget.style.display = 'inherit';
        // document.getElementById('bg-dropdown-search').style.display = 'inherit';
        this.runHeaderResearch();                        
    }

    runHeaderResearch(category) {
        console.log('runHeaderResearch');
        if (this.controller) { this.controller.abort(); } 
        this.controller = new AbortController();

        const signal = this.controller.signal;
        const url = new URL(window.location.origin);
        const pathname = '/rubrique';
        const that = this

        let qsearch = this.searchHeaderInputTarget.value;
        // let qsearch = inputSearch.value;
        url.searchParams.set('header', 1);
        url.searchParams.set('q', qsearch.replace('--', '-'));
        if (typeof category != 'undefined') { url.searchParams.set('id_typeProduit', category); }
        const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
        const new_url = new URL(`${url.origin}${pathname}?${new_params}`);
        fetch(new_url.href, { signal: signal, method: 'GET', headers: { 'X-Requested-With': 'XMLHttpRequest' } })
            // clean up, not really needed but may help to know if there is a pending request
            .then(r => {that.controller = null; return r;})
            .then(response => response.json())
            .then(function (data) {

                if (that.bgDropDownSearchTarget.style.display != 'none') {
                // if (document.getElementById('bg-dropdown-search').style.display != 'none') {
                    if (typeof category == 'undefined') {
                        that.suggestionListTarget.innerHTML = data.resultBySection;
                    }

                    that.searchCountTarget.innerText = data.found >= 10000 ? '10 000+' : data.found;
                    that.searchTextTarget.innerText = that.searchHeaderInputTarget.value;
                    that.suggestionListProductsTarget.innerHTML = data.resultByProduct;
                    that.dropdownSearchTarget.style.display = 'grid';

                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    document.body.style.overflow = 'hidden';

                }
            }).catch(function (error) {
            });
    }

    searchCategory(event) {
        this.runHeaderResearch(event.params.category);        
    }

    
}