import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';
import '../scss/pages/section/_plp.scss';

export default class extends Controller {

    static debounces = [
        'keyup', { name: 'headerResearch' },
    ];

    static targets = ['selectProductType', 'choiceType', 'choiceTypeTarget'];

    previousSearch = null;

    connect() {
        useDebounce(this, { wait: 300 });
        this._loadSpeedDimensions();
        this._loadEventsOfDimensions();
        this._loadPaginationConcertina();

        const dropdownSearch = document.getElementById('bg-dropdown-search');
        dropdownSearch.addEventListener('click', function (e) {
            dropdownSearch.style.display = 'none';
            document.getElementById('dropdown-search').style.display = 'none';
            document.body.style.overflow = 'auto';
        })

        const collapseFilterAction = document.getElementById('collapseFilterAction');
        if (collapseFilterAction) {
            collapseFilterAction.addEventListener('click', function (e) {
                let chevron = collapseFilterAction.querySelector('.fa-chevron-down');
                if (chevron) {
                    if (chevron.style.transform != 'rotate(0deg)') {
                        chevron.style.transform = 'rotate(0deg)';
                    } else {
                        chevron.style.transform = 'rotate(-90deg)';
                    }
                }
            })
        }


        this._loadNavigatorHistory();
    }

    _loadNavigatorHistory() {
        let that = this;
        window.onpopstate = function () {
            let new_url = new URL(window.location.href);
            that._loadPlp(new_url);
        };
    }

    choiceProductType(event) {
        // Mise à jour de l'url courante
        const url = new URL(window.location.href);
        let new_url = new URL(`${url.origin}${url.pathname}`);
        new_url.searchParams.set('id_typeProduit', this.choiceTypeTarget.value);
        const new_params = new URLSearchParams([...Array.from(new_url.searchParams.entries())]);
        new_url = new URL(`${url.origin}${url.pathname}?${new_params}`);
        this._loadPlp(new_url);
        this._history(new_url);
    }

    choiceProductInStock(event) {
        const checkbox = document.getElementById('fld_stock');
        const url = new URL(window.location.href);
        if (checkbox.checked) { url.searchParams.set('fld_stock', true); } else { url.searchParams.delete('fld_stock'); }
        const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
        const new_url = new URL(`${url.origin}${url.pathname}?${new_params}`);
        this._loadPlp(new_url);
        this._history(new_url);
    }

    choiceDestock(event) {
        const checkbox = document.getElementById('is_destockage');
        const url = new URL(window.location.href);
        if (checkbox.checked) { url.searchParams.set('is_destockage', 1); } else { url.searchParams.delete('is_destockage'); }
        const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
        const new_url = new URL(`${url.origin}${url.pathname}?${new_params}`);
        this._loadPlp(new_url);
        this._history(new_url);
    }

    choiceFamily(event) {
        let that = this;
        // Mise en place du loader
        const loader = document.getElementById('js-spinner-search');
        const search_dynamic_hp = document.getElementById('search_dynamic_hp');
        loader.classList.remove('d-none');
        search_dynamic_hp.innerHTML = '';
        // Récupération des informations
        const radio = document.querySelector('[name=id_famille]:checked');
        const pathname = '/hp-facets';
        const url = new URL(window.location.href);
        url.searchParams.set('id_famille', radio.value);
        url.searchParams.delete('page');
        const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
        const new_url = new URL(`${url.origin}${pathname}?${new_params}`);
        fetch(new_url.href, { method: 'GET', headers: { 'X-Requested-With': 'XMLHttpRequest' } })
            .then(response => response.json())
            .then(function (data) {
                loader.classList.add('d-none');
                search_dynamic_hp.innerHTML = data.render;
                $('.selectpicker').selectpicker('render');
                that._loadSpeedDimensions();
            })
    }

    changeQuickSearch(event) {
        // Mise à jour de l'url courante
        const url = new URL(window.location.href);
        let qsearch = event.target.value;
        url.searchParams.set('q', qsearch.replace('--','-'));
        url.searchParams.delete('page');
        const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
        const new_url = new URL(`${url.origin}${url.pathname}?${new_params}`);
        this._loadPlp(new_url);
        this._history(new_url);
    }

    arrayRemove(arr, value) {
        return arr.filter(function (geeks) {
            return geeks != value;
        });     
    }

    choiceLists(event) {
        const url = new URL(window.location.href);
        let champ = event.target.dataset.champ;
        let ids = url.searchParams.getAll(champ + '[]');
        if (ids.includes(event.target.value)) {
            ids = this.arrayRemove(ids, event.target.value)
            url.searchParams.delete(champ + '[]');
            ids.forEach(id => url.searchParams.append(champ + '[]', id));
        } else {
            url.searchParams.append(champ + '[]', event.target.value);
        }
        const refresh_form = url.searchParams.getAll(champ + '[]').length == 0;
        url.searchParams.delete('page');
        const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
        const new_url = new URL(`${url.origin}${url.pathname}?${new_params}`);
        this._loadPlp(new_url, refresh_form);
        this._history(new_url);
    }

    submitSpeedSearch(event) {
        let that = this;
        const url = new URL(window.location.origin);
        const pathname = '/rubrique';
        const radio = document.querySelector('[name=id_famille]:checked');
        let qsearch = document.getElementById('search').value;
        url.searchParams.set('id_famille', radio.value);
        url.searchParams.set('q', qsearch.replace('--','-'));
        document.querySelectorAll('#search-hp select').forEach(elt => {
            elt.disabled = true;
            url.searchParams.set(elt.name, elt.value);
        });
        const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
        const new_url = new URL(`${url.origin}${pathname}?${new_params}`);
        window.location.href = new_url.href;
    }

    headerResearch(event) {
        console.log('headerResearch');
        const nbLimit = 3;
        const inputSearch = event.target;

        if (inputSearch.value.length < nbLimit) {
            event.preventDefault();
            return false;
        }
        document.getElementById('bg-dropdown-search').style.display = 'inherit';

        const url = new URL(window.location.origin);
        const pathname = '/rubrique';
        let qsearch = inputSearch.value;
        url.searchParams.set('header', 1);
        url.searchParams.set('q', qsearch.replace('--', '-'));
        if (typeof category != 'undefined') { url.searchParams.set('id_typeProduit', category); }
        const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
        const new_url = new URL(`${url.origin}${pathname}?${new_params}`);
        fetch(new_url.href, { method: 'GET', headers: { 'X-Requested-With': 'XMLHttpRequest' } })
            .then(response => response.json())
            .then(function (data) {

                if (document.getElementById('bg-dropdown-search').style.display != 'none') {
                    if (typeof category == 'undefined') {
                        document.querySelector('#suggestionList').innerHTML = data.resultBySection;
                    }

                    document.getElementById('result-search-header').addEventListener('click', function () {
                        document.querySelector('.search-header-submit').click();
                    });

                    document.getElementById('search-count').innerText = data.found >= 10000 ? '10 000+' : data.found;
                    document.getElementById('search-text').innerText = inputSearch.value;

                    document.querySelector('#suggestionListProducts').innerHTML = data.resultByProduct;
                    document.getElementById('dropdown-search').style.display = 'grid';
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    document.body.style.overflow = 'hidden';

                    let typeProductSuggest = document.querySelectorAll('#suggestionList li');
                    typeProductSuggest.forEach(elt => {
                        elt.addEventListener('click', function (e) {
                            func(inputSearch, elt.dataset.category);
                        })
                    })
                }
            })
                        
    }

    applySearch(event) {
        const url = new URL(window.location.href);
        this._loadPlp(url, false);
    }

    removeOneFilter(event) {
        // Récupération du champ et de la valeur à supprimer
        const champ = event.target.dataset.champ;
        const value = event.target.dataset.value;
        // On veut savoir si c'est un paramètre multiple ou non
        let url = new URL(window.location.href);
        const values = url.searchParams.getAll(champ + '[]');
        // Si c'est un paramètre multiple
        if (values.length > 1) {
            // On supprime la valeur du paramètre
            url.searchParams.delete(champ + '[]');
            values.forEach(val => {
                if (val != value) { url.searchParams.append(champ + '[]', val); }
            });
        } else {
            // Si c'est un paramètre unique
            url.searchParams.delete(champ);
            url.searchParams.delete(champ + '[]');
        }
        // On recharge la page
        const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
        const new_url = new URL(`${url.origin}${url.pathname}?${new_params}`);         
        this._loadPlp(new_url, true);
        this._history(new_url);
    }

    removeFilters(event) {
        const url = new URL(window.location.href);
        const new_url = new URL(`${url.origin}${url.pathname}`);
        this._loadPlp(new_url, true);
        this._history(new_url);
    }

    async _loadPlp(new_url, refresh_form = true) {
        console.log('loadPlp');
        let that = this;
        // Mise en place d'un loader
        const loader = document.getElementById('js-loading');
        loader.setAttribute('aria-hidden', 'false');
        loader.style.display = null;
        const refresh_mode = refresh_form ? 'all' :'partial';
        // Récupération des informations de la page selon la nouvelle url
        fetch(new_url.href, { method: 'GET', headers: { 'X-Requested-With': 'XMLHttpRequest' } })
            .then(response => response.json())
            .then(function (data) {
                // Mise à jour des facets
                const htmlFacet = document.getElementById('section-facet');
                if ('all' == refresh_mode) {
                    htmlFacet.innerHTML = data.facetView;
                } else if ('partial' == refresh_mode) {
                    var divFacet = document.createElement('div');
                    divFacet.innerHTML = data.facetView;
                    // Récupération des filtres select des dimensions
                    var selectpickerNumber = htmlFacet.getElementsByClassName('selectpicker_number'); 
                    var selectpickerNumberDiv = divFacet.getElementsByClassName('selectpicker_number');
                    if (typeof selectpickerNumber[0] != 'undefined' && typeof selectpickerNumberDiv[0] != 'undefined') {
                        selectpickerNumber[0].innerHTML = selectpickerNumberDiv[0].innerHTML;
                    }
                    // Récupération des filtres actifs
                    var recapFilters = document.getElementById('recapFilters');
                    var recapFiltersDiv = divFacet.querySelector('#recapFilters');
                    if (recapFilters && recapFiltersDiv) {
                        recapFilters.innerHTML = recapFiltersDiv.innerHTML;
                    }
                }
                // Mise à jour des produits proposés
                const htmlContent = document.getElementsByClassName('js-filter-content');
                for (let element of htmlContent) { element.innerHTML = data.contentView; element.style.display = 'grid'; }
                const htmlPagination = document.getElementById('section-pagination-concertina');
                htmlPagination.innerHTML = data.paginationView;
                // Mise à jour de la bonne url pour la pagination
                const paginationConcertinaLink = document.querySelectorAll("#section-pagination-concertina > ul li a");
                paginationConcertinaLink.forEach(elt => { that._paginationConcertinaEvent(elt); });
                // Mise à jour du seo
                if (typeof data.seoTemplate != 'undefined') {
                    const collapseSEO = document.getElementById('collapseSEO');
                    if (collapseSEO) { collapseSEO.innerHTML = data.seoTemplate; }
                }
                // On demande aux differents select utilisant selectpicker de refaire leur rendu
                $('.selectpicker').selectpicker('render');
                if ('partial' == refresh_mode) {
                    that._loadEventsOfDimensions();
                }
                // On retire le loader
                loader.setAttribute('aria-hidden', 'true');
                loader.style.display = 'none';
                // Scroll to top
                window.scrollTo({ top: 0, behavior: 'smooth' });
                collapseFilterAction.click();
            });

    }

    async _paginationConcertinaEvent(elt) {
        const url = new URL(window.location.href);
        url.searchParams.set('page', elt.getAttribute('data-page'));
        const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
        const new_url = new URL(`${url.origin}${url.pathname}?${new_params}`);
        elt.href = new_url.href;
    }

    _loadEventsOfDimensions() {
        let choiceDimensions = document.querySelectorAll('.selectpicker_number select');
        choiceDimensions.forEach(elt => {
            elt.addEventListener('change', (e) => {
                e.preventDefault();
                const url = new URL(window.location.href);
                url.searchParams.set(elt.name, elt.value);
                url.searchParams.delete('page');
                const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
                const new_url = new URL(`${url.origin}${url.pathname}?${new_params}`);
                this._loadPlp(new_url);
                this._history(new_url);
            });
        });
    }

    _loadSpeedDimensions() {
        let that = this;
        let speedChoiceDimensions = document.querySelectorAll('#search-hp select');
        speedChoiceDimensions.forEach(elt => {
            elt.addEventListener('change', (e) => {
                e.preventDefault();
                const url = new URL(window.location.origin);
                const pathname = '/hp-facets';
                const radio = document.querySelector('[name=id_famille]:checked');
                url.searchParams.set('id_famille', radio.value);
                document.querySelectorAll('#search-hp select').forEach(elt => {
                    elt.disabled = true;
                    url.searchParams.set(elt.name, elt.value);
                });
                const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
                const new_url = new URL(`${url.origin}${pathname}?${new_params}`);
                fetch(new_url.href, { method: 'GET', headers: { 'X-Requested-With': 'XMLHttpRequest' } })
                    .then(response => response.json())
                    .then(function (data) {
                        search_dynamic_hp.innerHTML = data.render;
                        $('.selectpicker').selectpicker('render');
                        that._loadSpeedDimensions();
                    })
            });
        });
    }

    _loadPaginationConcertina() {
        let paginationConcertinaLink = document.querySelectorAll("#section-pagination-concertina > ul li a");
        paginationConcertinaLink.forEach(elt => {
            elt.addEventListener('click', (e) => {
                e.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();
                const url = new URL(window.location.href);
                url.searchParams.set('page', elt.dataset.page);
                const new_params = new URLSearchParams([...Array.from(url.searchParams.entries())]);
                const new_url = new URL(`${url.origin}${url.pathname}?${new_params}`);
                elt.href = new_url.href;
                this._loadPlp(new_url);
                this._history(new_url);
            });
        });
    }

    _history(new_url) {
        history.pushState({}, '', new_url.href);
        history.replaceState({}, '', new_url.href);
    }

}