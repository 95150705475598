import stopPropagation from '../system/stopPropagation.js';

$(function () {
    initButtonQuotation()
});

function initButtonQuotation() {
    initJsAction('.js-QuotationToOrder', QuotationToOrder);
    initJsAction('.js-QuotationCancel', QuotationCancel);
}

function initJsAction(_selector, _function) {
    $(_selector).on('click', function (_e) {
        stopPropagation(_e);
        var target = $(_e.delegateTarget);
        stopPropagation(target);
        var id = $(target).attr('data-id');
        _function(id, target);
    });
}

function QuotationToOrder(_id, _target) {
    $.ajax({
        type: 'POST',
        url: `/quotation-to-order/${_id}`,
        success: function (_ret) {
            window.location.href = _ret;
        },
        error: function (jqXHR, textStatus, errorThrown) {
            sendNotificationData(`/quotation-to-order/${_id}`, textStatus, errorThrown);     
        }
    });
}

function QuotationCancel(_id, _target) {
    $.ajax({
        type: 'POST',
        dataType: "json",
        url: `/quotation-cancel/${_id}`,
        success: function (_ret) {
            if (_ret.modal) {
                $('body').append(_ret.modal);
                ModalShow('.modal-contact');
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            sendNotificationData(`/quotation-cancel/${_id}`, textStatus, errorThrown);     
        }
    });
}

function QuotationDelete(_id) {
    $.ajax({
        type: 'POST',
        url: `/quotation-delete/${_id}`,
        success: function (_ret) {
            if (_ret.modal) {
                $('body').append(_ret.modal);
                ModalShow('.modal-contact');
            } else {
                location.reload();
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            sendNotificationData(`/quotation-delete/${_id}`, textStatus, errorThrown);     
        }
    });
}


function ModalShow(_selector) {
    $(_selector).modal({ backdrop: true, keyboard: true, focus: true, show: true });
    $(_selector).on('hidden.bs.modal', function (_e) {
        $(_selector).remove();
    });
}
global.initButtonQuotation = initButtonQuotation;
global.QuotationDelete = QuotationDelete;