import { Controller } from '@hotwired/stimulus';
import { fetchCall } from '../js/helper/fetch.js';

export default class extends Controller {

    static values = {

        data: Object
    };

    connect() {}

    async openModal(event) {

        let modal = await fetchCall(this.dataValue.urlModal, "POST", this.dataValue);

        if (modal != false) {
            this.initModal(this.dataValue.id, modal.view);
        }
    }
  
    initModal(id, view, selecpicker = false, backdrop = false) {

        // Ajoute la modal au DOM
        document.body.insertAdjacentHTML('beforeend', view);

        let options = {
            show: true,
            keyboard: true
        };

        if (backdrop) {
            options.backdrop = 'static';
        }

        var myModal = new bootstrap.Modal(document.getElementById(id), options);
        myModal.show();

        if (selecpicker) {
            $('.selectpicker').selectpicker();
        }

        document.getElementById(id).addEventListener('hidden.bs.modal', function () {
            this.remove();
        });
    }

}