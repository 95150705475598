export function sanitizeInput(input)
{
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
        "`": '&grave;',
        "=": '&#x3D;'
    };
    const reg = /[&<>"'/`=]/ig;
    return input.replace(reg, (match) => (map[match]));
}