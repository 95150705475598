export async function fetchCall(route, type = "GET", params = "", withStringify = true)
{
    let myInit = {};
    
    if (type.toUpperCase() == "POST") {

        let data = new FormData();

        // Si c'est déjà un FormData on ne le stringify pas
        if (withStringify) {
            data.append("json", JSON.stringify(params));
        } else {
            data = params;
        }

        myInit = {
            method: "POST",
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            body: data
        };
    }

    try {

        let response = await fetch(route, myInit);

        if (!response.ok) {
            return false;
        }

        let data = await response.json();
        return data;

    } catch (error) {
        return false;
    }
}