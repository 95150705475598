import { Controller } from '@hotwired/stimulus';
import { fetchCall } from '../js/helper/fetch.js';
import { sanitizeInput } from '../js/helper/sanitize.js';

export default class extends Controller {

    static targets = [
        "formContactInfos",
        "errorMessage",
        "messageConfirm"
    ];

    static values = {
        traductionFichierTropVolumineux: String,
    };

    connect() {
        
        $('.selectpicker').selectpicker();
    }

    async contactInfos(event) {

        event.preventDefault();
        event.stopPropagation();

        let filesInput = document.getElementById('filePhoto');

        let totalSize = 0;
        if (filesInput.files.length > 0) {
            Array.from(filesInput.files).forEach(value => {
                totalSize += value.size;
            });
        }
        if (totalSize > 2000000) {
            this.errorMessageTarget.classList.remove('d-none');
            this.errorMessageTarget.innerHTML = this.traductionFichierTropVolumineuxValue;
            return;
        }

        if (document.getElementById('adresse')) {

            if (document.getElementById('adresse').value.trim() != "" && document.getElementById('CP').value.trim() == "" && document.getElementById('ville').value.trim() == "") {
                
                document.getElementById('CP').setAttribute('required', 'required');
                document.getElementById('ville').setAttribute('required', 'required');
                document.getElementById('nomAdresse').setAttribute('required', 'required');
                document.getElementById('prenomAdresse').setAttribute('required', 'required');
                if (document.getElementById('raisonSociale')) {
                    document.getElementById('raisonSociale').setAttribute('required', 'required');
                }
            } else {
                document.getElementById('CP').removeAttribute('required');
                document.getElementById('ville').removeAttribute('required');
                document.getElementById('nomAdresse').removeAttribute('required');
                document.getElementById('prenomAdresse').removeAttribute('required');
                if (document.getElementById('raisonSociale')) {
                    document.getElementById('raisonSociale').removeAttribute('required');
                }
            }
        }

        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.querySelectorAll('.needs-validation');

        let checkValidation = true;
        // Loop over them and prevent submission
        Array.from(forms).forEach(form => {
            
            form.classList.add('was-validated');
                if (!form.checkValidity()) {
                    checkValidation = false;
                } 
        });

        if (!checkValidation) {
            return;
        }

        let data = new FormData(this.formContactInfosTarget);

        data.set('nom', document.getElementById('nom').value.trim());
        data.set('prenom', document.getElementById('prenom').value.trim());
        data.set('phone', document.getElementById('phone').value.trim());
        data.set('mailContact', document.getElementById('mailContact').value.trim());
        data.set('message', document.getElementById('message').value.trim());

        if (document.getElementById('adresse')) {
            
            let action = 'add-address';
            const { countryCode: isoCountry = 'FRA', iso: language = 'FR' } = document.querySelector('#pays').selectedOptions[0].dataset;
            let isoEtat = document.getElementById('etat') ? document.getElementById('etat').options[document.getElementById('etat').selectedIndex].dataset.valeuretat || "" : "";
            var raisonSocial = document.getElementById('raisonSociale') ? sanitizeInput(document.getElementById('raisonSociale').value.trim()) : '';
            var lieuDit = document.getElementById('lieu_dit') ? sanitizeInput(document.getElementById('lieu_dit').value.trim()) : '';
    
            data.set('countryCode', isoCountry);
            data.set('languageCode', language.toLowerCase());
            data.set('idAddress', null);
            data.set('action', action);
            data.set('libelleAdresse', sanitizeInput(document.getElementById('libelle').value.trim()));
            data.set('raisonSocialeAdresse', raisonSocial);
            data.set('nomAdresse', sanitizeInput(document.getElementById('nomAdresse').value.trim().toUpperCase()));
            data.set('prenomAdresse', sanitizeInput(document.getElementById('prenomAdresse').value.trim().toUpperCase()));
            data.set('adresse', sanitizeInput(document.getElementById('adresse').value.trim()));
            data.set('complement', sanitizeInput(document.getElementById('complement').value.trim()));
            data.set('codePostal', sanitizeInput(document.getElementById('CP').value.trim()));
            data.set('ville', sanitizeInput(document.getElementById('ville').value.trim()));
            data.set('lieu_dit', lieuDit);
            data.set('etat', isoEtat);
            data.set('pays', document.querySelector('#pays').selectedOptions[0].value || 65);
        }

        let contactInfos = await fetchCall(this.formContactInfosTarget.getAttribute('action'), "POST", data, false);

        if (contactInfos.status == "success") {

            this.formContactInfosTarget.classList.add('d-none');
            window.scrollTo(0, 0);
            this.messageConfirmTarget.classList.remove('d-none');
            document.querySelector('body').insertAdjacentHTML('beforeend', contactInfos.dataLayer);

        } else {

            this.errorMessageTarget.classList.remove('d-none');
            this.errorMessageTarget.innerHTML = contactInfos.message;
        }
    }
}