export function setEmplacementNbItem() {
    $('a.DataLayerProduct').on('click', function (event) {
        // Empêche la redirection par défaut vers le lien href
        event.preventDefault();
        // Récupérez les informations de la card sélectionnée
        var emplacement = $(this).attr('data-emplacement');
        var nbItem = $(this).attr('data-dlitem');
        var idProduct = $(this).attr('data-id');
        // Enregistrez les informations dans la session
        sessionStorage.setItem('dataLayerProductEmplacement_'+idProduct, emplacement);
        sessionStorage.setItem('dataLayerProductSelectItemNbItem_'+idProduct, nbItem);
        // Redirigez l'utilisateur vers le lien href
        window.location.href = $(this).attr('href');
    });
}

global.setEmplacementNbItem = setEmplacementNbItem;