import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = [
       'formChangeMotDePasse'
    ];

    static values = {
    };

    connect() {
        
        // On force bootstrap à valider selon ce que nous on veux et pas nativement
        document.querySelector('#mdpContact').setCustomValidity(false);
        document.querySelector('#mdpContact2').setCustomValidity(false);
        document.querySelector('#mdpContact').addEventListener('keyup', checkPasswordLength);
        document.querySelector('#mdpContact2').addEventListener('keyup', checkValidityPassword);
        document.querySelector('#mdpContact').addEventListener('keyup', checkValidityPassword);

        function checkPasswordLength() {
            document.querySelector('#mdpContact').setCustomValidity( 
                document.querySelector('#mdpContact').value.length >= 8 ? "" : false
            );
        }
        
        function checkValidityPassword() {
            document.querySelector('#mdpContact2').setCustomValidity( 
                document.querySelector('#mdpContact2').value == document.querySelector('#mdpContact').value ? "" : false
            );
        }
    }

    async changeMotDePasseAction(event) {

        let form = this.formChangeMotDePasseTarget;

        $.ajax({
            type: "POST",
            cache: false,
            url: form.getAttribute('action'),
            dataType: "json",
            data: {
                loginContact:   form.querySelector('input[name=loginContact]').value,
                oldMdpContact:  form.querySelector('input[name=oldMdpContact]').value,
                mdpContact:     form.querySelector('input[name=mdpContact]').value,
                mdpContact2:    form.querySelector('input[name=mdpContact2]').value,
            },
            success: function (res) {
                if (res.status == 0) {
                    form.querySelector('.err').innerText = res.message;
                    form.querySelector('.err').classList.remove('d-none');
                } else {
                    window.location.reload();
                }
            },
        });
    }
}