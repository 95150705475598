import { Controller } from '@hotwired/stimulus';
import { useWindowResize } from 'stimulus-use'
import '../js/load_modal_contact.js';

export default class extends Controller {

    _count_add = [];
    _count_sub = [];
    _count_upd = [];
    _count_del = [];

    connect()
    {
        this.loadStickyContainerValidateBasket(false);
        useWindowResize(this)
        window.addEventListener("onScroll", window);
    }


    onScroll(event)
    {
        this.loadStickyContainerValidateBasket();
    }

    windowResize({ width, height, event })
    {
        this.loadStickyContainerValidateBasket();
    }

    async subProduct(event)
    {
        await this.basketSub(event);
    }

    _hide(list)
    {
        Array.from(list).forEach((element) => { element.style.display = 'none'; });
    }

    _show(list)
    {
        Array.from(list).forEach((element) => { element.style.display = 'block'; });
    }

    _addClass(list, className)
    {
        Array.from(list).forEach((element) => { element.classList.add(className); });
    }

    _removeClass(list, className)
    {
        Array.from(list).forEach((element) => { element.classList.remove(className); });
    }

    _datalayerCart(dataLayer)
    {
        const divFragment = document.createRange().createContextualFragment(`${dataLayer}`);
        document.body.prepend(divFragment);
    }

    async inputUpdate(event)
    {
        let _id = event.params.id;
        this._hide(document.getElementsByClassName(`inputPrepend-${_id}`));
        this._hide(document.querySelectorAll(`.buttonAdd-${_id}`));
        this._show(document.querySelectorAll(`.buttonOk-${_id}`));
        this._addClass(document.querySelectorAll(`#inputUpdate-${_id}`), 'ok');
        return false;
    }

    async inputEnter(event)
    {
        let _id = event.params.id;
        let formElement = event.target.closest('form');
        let buttonElement = formElement.querySelector('.buttonOk-' + _id);
        await buttonElement.click();

        return false;
    }

    async inputOk(event)
    {
        let parent = event.target.closest('form');
        Array.from(parent.children).forEach(child => {
            if (!child.classList.contains('wait-onload')) {
                child.classList.add('d-none');
            }
        });
        parent.querySelector('.wait-onload').classList.remove('d-none');
        parent.querySelector('.wait-onload').classList.add('d-flex');
        await this.basketUpdate(event, false);

        return false;
    }

    async basketRestore(event)
    {
        await this.basketUpdate(event, false);
        const toastElement = event.target.closest('.toast');
        if (toastElement) {
            toastElement.remove();
        }
        return false;
    }

    async addProduct(event)
    {
        var input = document.getElementById('inputUpdate-' + event.params.id);
        if (input == null) {
            buttonOnLoad('.buttonAdd-' + event.params.id);
        }
        await this.basketAdd(event);
        
        return false;
    }

    async preOrder(event)
    {
        await loadModalContact('devis', event.params.id);
        return false;
    }

    async basketAdd(_event) {
        var _search = _event.params.search || 0;
        // on génére un id d'éxecution
        var _id_add = Math.random();
        this._count_add[_event.params.id] = _id_add; // on stock la dernière éxécution
        let that = this;
    
        await fetch(`/basket/add/${_event.params.id}/${_event.params.idRef}/${_event.params.needRecap}/${_search}/${_event.params.emplacement}/${_event.params.dlitem}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(t => {
            that._datalayerCart(t.dataLayer);    
            // si pas la dernière exécution on zap le rendu
            if (that._count_add[_event.params.id] == _id_add) {
    
                if (t.count > 1) {
                    // Mise a jour panier -> t.count
    
                    if (window.sib) { window.sendinblue.track('cart_updated', {
                        "email": window.sib.email_id, 
                        "FIRSTNAME": window.sib.firstname,
                        "LASTNAME": window.sib.lastname,
                    }, JSON.parse(t.cart_updated)); }
                } else {
                    if (t.countPanier.nb == 1) {
                        // Creation du panier
                        if (window.sib) { window.sendinblue.track('cart_created', {
                            "email": window.sib.email_id,
                            "FIRSTNAME": window.sib.firstname,
                            "LASTNAME": window.sib.lastname,
                        }); }
                    } else {
                        // Ajout au panier
                        if (window.sib) { window.sendinblue.track('cart_updated', {
                            "email": window.sib.email_id,
                            "FIRSTNAME": window.sib.firstname,
                            "LASTNAME": window.sib.lastname,
                        }, JSON.parse(t.cart_updated)); }
                    }
                }
    
                this.renderReturnBasket(_event.params.id, t)
                if (_event.params.label !== undefined && _event.params.label != '') {
                    
                    // MIT2023-345 - CRD - 2023-04-18 - Gestion de didomi sur KNL 
                    // MIT2023-214 - CRD - 2023-06-04 - Gestion de didomi sur KNL(déjà fait)KBE, RBE, BEU, KAT, RES
                    // MIT2023-578 - CRD - 2023-06-06 - Gestion didomi sur CIT, KDE, BCOM, RCOM, BCOUK
                    // MIT2023-239 - CLE - 2023-06-28 - Suppression CookieBot
                    if (typeof analytics != 'undefined') {
                        analytics.track('click', {
                            category: 'Ajout au panier',
                            label: _label,
                            value: 0
                        });
                    }
                }
            }
        });
        return false;
    }

    async basketSub(_event)
    {
        var _search = _event.params.search || 0;
    
        // on génére un id d'éxecution
        var _id_sub = Math.random();
        this._count_sub[_event.params.id] = _id_sub; // on stock la dernière éxécution
        let that = this;
        await fetch(`/basket/sub/${_event.params.id}/${_event.params.idRef}/${_event.params.needRecap}/0/${_search}/${_event.params.emplacement}/${_event.params.dlitem}`, {
            method: 'POST',
        })
            .then(response => response.json())
            .then(function (t) {
            // si pas la dernière exécution on zap le rendu
            if (that._count_sub[_event.params.id] == _id_sub) {
                // Mise a jour produit du panier -> t.count
                if (window.sib) { window.sendinblue.track('cart_updated', {
                    "email": window.sib.email_id,
                    "FIRSTNAME": window.sib.firstname,
                    "LASTNAME": window.sib.lastname,
                }, JSON.parse(t.cart_updated)); }
                that.renderReturnBasket(_event.params.id, t);
                that._datalayerCart(t.dataLayer);
            }
        });
        return false;
    }

    async basketUpdate(_event, _force)
    {    
        let _id = _event.params.id;
        // on génére un id d'éxecution
        var _id_upd = Math.random();
        this._count_upd[_event.params.id] = _id_upd; // on stock la dernière éxécution
        var that = this;
    
        var form = _event.target.closest('form');
        var input = form.querySelector(`#inputUpdate-${_id}`);
        var _val = input.value;
    
        var _search = _event.params.search || 0;
        if (_val != '' && _val < 2147483647) {
            await fetch(`/basket/update/${_event.params.id}/${_event.params.idRef}/${_val}/${_search}/${_event.params.needRecap}/${_force}/${_event.params.emplacement}/${_event.params.dlitem}`, {
                method: 'POST',
            })
                .then(response => response.json())
                .then(function (t) {
                    // si pas la dernière exécution on zap le rendu
                    if (that._count_upd[_event.params.id] == _id_upd) {
                        // Mise a jour produit du panier -> _val
                        if (window.sib) {
                            window.sendinblue.track('cart_updated', {
                                "email": window.sib.email_id,
                                "FIRSTNAME": window.sib.firstname,
                                "LASTNAME": window.sib.lastname,
                            }, JSON.parse(t.cart_updated));
                        }
                        that.renderReturnBasket(_event.params.id, t);
                        that._datalayerCart(t.dataLayer);
                    }
                });
        }
    }

    async basketDelete(_event)
    {
        let _search = _event.params.search || 0;
    
        // on génére un id d'éxecution
        let _id_del = Math.random();
        this._count_del[_event.params.id] = _id_del; // on stock la dernière éxécution
        var that = this;
        let _val = 0;
        let _force = 1;
        await fetch(`/basket/update/${_event.params.id}/${_event.params.idRef}/${_val}/${_force}/${_event.params.needRecap}/${_search}/${_event.params.$emplacement}/${_event.params.dlitem}`, {
            method: 'POST',
        })
            .then(response => response.json())
            .then(function (t) {
            // si pas la dernière exécution on zap le rendu
            if (that._count_del[_event.params.id] == _id_del) {
                // Suppression produit du panier
                if (window.sib) { window.sendinblue.track('cart_updated', {
                    "email": window.sib.email_id,
                    "FIRSTNAME": window.sib.firstname,
                    "LASTNAME": window.sib.lastname,
                }, JSON.parse(t.cart_updated)); }
                that.renderReturnBasket(_event.params.id, t);
                that._datalayerCart(t.dataLayer);
            }
        });
    }

    async renderReturnBasketWithId(_id, _json)
    {
        document.querySelectorAll(`.card-product-${_id}`).forEach((element) => { element.innerHTML = _json.updateQuantiteRubrique; });
        if (_json.updateQuantiteBasket != '') {
            let productBasket = Array.from(document.querySelectorAll(`#productBasket-${_id}`));
            if (productBasket.length == 0) {
                let originalElement = document.querySelector('#productBasket-none');
                if (originalElement != null) {
                    let clonedElement = originalElement.cloneNode(true);
                    clonedElement.id = `productBasket-${_id}`;
                    clonedElement.innerHTML = _json.updateQuantiteBasket;
                    clonedElement.style.display = 'block';
                    document.querySelector('#basketContents').appendChild(clonedElement);
                }
            } else {
                document.querySelectorAll(`#productBasket-${_id}`).forEach((element) => { element.innerHTML = _json.updateQuantiteBasket; });
                // on force le need recap dans le conseil de l'expert
                document.querySelectorAll(`#inputUpdate-${_id}`).forEach((element) => { element.setAttribute('data-need-recap', 'true'); });
            }
        } else {
            let productBasket = document.getElementById(`productBasket-${_id}`);
            if (productBasket != null) {
                productBasket.remove();
            }
            // $(`#productBasket-${_id}`).remove();
            if(document.querySelectorAll('#basketContents .productBasket').length == 0) {
                this._show(document.querySelectorAll('#card-empty'));
            } else {
                this._hide(document.querySelectorAll('#card-empty'));
            }
        }
        if (_json.recap != '') {
            this._hide(document.querySelectorAll('#card-empty'));
            document.querySelectorAll('.card-recap').forEach((element) => { element.innerHTML = _json.recap; });
        }

        if (_json.countPanier.nb == 0) {
            // Suppression du panier
            if (window.sib) { window.sendinblue.track('cart_deleted', {
                "email": window.sib.email_id,
                "FIRSTNAME": window.sib.firstname,
                "LASTNAME": window.sib.lastname,
            }); }

            this._show(document.querySelectorAll('#card-empty'))
        } else {
            this._hide(document.querySelectorAll('#card-empty'));
        }
    
    }

    async renderBasketHeader(_json)
    {
        let that = this;
        let __nb = _json.countPanier.nb;
        let __nbCalc = parseInt(__nb.toString().replace('/\s/g', ''), 10) + 0;
        const headerBasketUpdate = document.getElementsByClassName('header_basket_update')[0];
        const headerBasketTotal = document.getElementById('header_basket_total');
        const headerBasketCount = document.getElementsByClassName('header_basket_count')[0];
        let __old = parseInt(headerBasketCount.innerHTML.toString().replace('/\s/g', ''), 10) + 0;
        if (__old > __nbCalc) {
            headerBasketUpdate.innerHTML = parseInt(__nbCalc - __old, 10);
        }
        else {
            headerBasketUpdate.innerHTML = '+' + parseInt(__nbCalc - __old, 10);
        }
        headerBasketTotal.innerHTML = _json.totalBasket;
        this._addClass(document.getElementsByClassName('shopcar'), 'active');
        setTimeout(function () {
            that._removeClass(document.getElementsByClassName('shopcar'), 'active');
        }, 1000);
        headerBasketCount.innerHTML = __nb;
    }
    
    async renderReturnBasket(_id, _json)
    {
        if (_id != '') {
            await this.renderReturnBasketWithId(_id, _json);
        }

        await this.renderBasketHeader(_json);        
        
        if (_json.toast != '') {
            await this.renderToast(_json.toast);
        }
    }

    async renderToast(_toast)
    {
        let toast = document.createElement('div');
        toast.innerHTML = _toast;
        document.getElementById('toast-container').appendChild(toast);

        let myToastEl = document.querySelector('.toast');
    
        if (myToastEl) { 
            let myToast = bootstrap.Toast.getOrCreateInstance(myToastEl) // Returns a Bootstrap toast instance
            myToast.show();
        }
    
        Array.from(document.getElementsByClassName('toast')).forEach((element) => {
            element.addEventListener('hidden.bs.toast', function () {
                element.remove();
            });
        });
    }

    loadStickyContainerValidateBasket(withValidation = true)
    {
        const container = document.getElementById("fixed-container-validateBasket");
        const basketLeft = document.getElementById("basket-left");

        // Si mon container existe et si la résolution est bonne
        if (container !== null && window.innerWidth < 992) {

            this._addClass(basketLeft, "mt-7rem");
                
            if (withValidation) {
                const { scrollTop, clientHeight } = document.documentElement;

                const validateBasketBtn = document.querySelector("#validateBasket[data-fixed='none']");
                const validateBasketBtn_Position = validateBasketBtn.getBoundingClientRect().top;
                const container_Height = container.offsetHeight;
                const navbar_Height = document.querySelector("nav.navbar-123").offsetHeight;
            
                let windowHeight = clientHeight - scrollTop;
            
                if (scrollTop > validateBasketBtn_Position.toFixed() - windowHeight) {
                    container.style.top = -container_Height + navbar_Height + "px";
                } else {
                    container.style.top = navbar_Height + "px";
                }
            }
            
        } else if (basketLeft !== null) {
            this._removeClass(basketLeft, "mt-7rem");
        }
    }

}